.awssld {
    --organic-arrow-color: #ebe6e6;
}

@media only screen and (min-width: 100px) {
    .awssld {
        --slider-height-percentage: 16%;
    }
}

@media only screen and (min-width: 800px) {
    .awssld {
        --slider-height-percentage: 28%;
    }
}