@font-face {
  font-family:'TradeGothic';
  src: url('./assets/fonts/TradeGothic-Oblique.eot');
src: url('./assets/fonts/TradeGothic-Oblique.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/TradeGothic-Oblique.woff2') format('woff2'),
  url('./assets/fonts/TradeGothic-Oblique.woff') format('woff'),
  url('./assets/fonts/TradeGothic-Oblique.svg#TradeGothic-Oblique') format('svg');
  font-weight: 500;
  font-style: oblique;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'TradeGothic';
  src: url('./assets/fonts/TradeGothic-Bold.eot');
src: url('./assets/fonts/TradeGothic-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/TradeGothic-Bold.woff2') format('woff2'),
  url('./assets/fonts/TradeGothic-Bold.woff') format('woff'),
  url('./assets/fonts/TradeGothic-Bold.svg#TradeGothic-Bold') format('svg');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'TradeGothic';
  src: url('./assets/fonts/TradeGothicLTBold.eot');
  src: url('./assets/fonts/TradeGothicLTBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/TradeGothicLTBold.woff2') format('woff2'),
  url('./assets/fonts/TradeGothicLTBold.woff') format('woff'),
  url('./assets/fonts/TradeGothicLTBold.svg#TradeGothicLTBold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'TradeGothic';
  src: url('./assets/fonts/TradeGothicLTStd.eot');
src: url('./assets/fonts/TradeGothicLTStd.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/TradeGothicLTStd.woff2') format('woff2'),
  url('./assets/fonts/TradeGothicLTStd.woff') format('woff'),
  url('./assets/fonts/TradeGothicLTStd.svg#TradeGothicLTStd') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

body {
  margin: 0;
}

.active-header {
  box-shadow: 0 0 40px rgba(0,0,0,0.15);
}

.no-overflow {
  overflow: hidden;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.react-share__ShareButton, .react-share__ShareButton:hover, .react-share__ShareButton:focus, .react-share__ShareButton:active {
  text-decoration: none;
  outline: none;
}

.filterDropdown .Dropdown-control{
  height: 50px;
  border: 0;
  font-size: 22px;
  font-family: 'TradeGothic';
  font-weight: 700;
  padding-top: 0;
  cursor: pointer;
}

.filterDropdown .Dropdown-placeholder {
  line-height: 50px;
  height: 50px;
}

.filterDropdown .Dropdown-menu {
  line-height: 40px;
  font-size: 22px;
  font-family: 'TradeGothic';
  font-weight: 500;
  text-transform: uppercase;
}
.filterDropdown .Dropdown-option {
  padding-left: 15px;
}

.filterDropdown .Dropdown-option:hover {
  background-color: rgba(90, 90, 90, 1) !important;
  color: #fff;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}